<!--
* @description:
* @fileName channelData.vue
* @author hvv
* @date 2022/07/18 13:50:46
!-->
<template>
  <div>
    <vab-query-form style="padding-top: 20px">
      <vab-query-form-left-panel :span="12"></vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label="渠道名称">
            <el-input
              placeholder="请输入渠道名称"
              v-model="queryForm.channelName"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        label="渠道名称"
        prop="channelName"
        show-overflow-tooltip
      />
      <el-table-column label="渠道描述" prop="remarks" show-overflow-tooltip />
      <el-table-column
        label="渠道唯一标识"
        prop="channelNo"
        show-overflow-tooltip
      />
      <el-table-column
        label="渠道二维码"
        prop="channelQrcodeUrl"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-image
            style="width: 100px; height: 100px"
            :src="row.channelQrcodeUrl"
            fit="cover"
          />
        </template>
      </el-table-column>

      <el-table-column
        label="渠道链接"
        prop="channelUrl"
        show-overflow-tooltip
      />
      <el-table-column label="操作" show-overflow-tooltip width="350">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">
            <vab-icon icon="edit-2-line" />
            编辑
          </el-button>
          <el-button type="primary" @click="downLoad(row)">
            下载渠道码
          </el-button>
          <el-button type="primary" @click="copy(row, $event)">
            获取渠道链接
          </el-button>
          <!-- <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button> -->
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNumber"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />

    <edit ref="editRef" @fetch-data="fetchData" :eventId="queryForm.eventId" />
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
    onActivated,
  } from 'vue'

  import { Delete, Plus, Search } from '@element-plus/icons'
  import { getChannelList } from '@/api/channel'

  import handleClipboard from '@/utils/clipboard'
  export default defineComponent({
    name: 'channelManagar',
    components: {
      Edit: defineAsyncComponent(() => import('./components/channelEdit')),
    },
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        queryForm: {
          channelName: '',
          pageNum: 1,
          pageSize: 20,
          eventId: '',
        },
        total: 0,
        shopList: [],
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        editRef: null,
        typeOptions: [
          {
            label: '全部',
            value: '',
          },
          {
            label: '申请会议',
            value: 'HOLD_MEETING',
          },
          {
            label: '申请展览',
            value: 'EXHIBITION',
          },
        ],
      })

      const handleEdit = (row) => {
        console.log(row)
        if (row.eventId) {
          state.editRef.showEdit(row)
        } else {
          state['editRef'].showEdit()
        }
      }

      const handleDelete = (row) => {
        if (row.id) {
          proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await delCoupon(row.id)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            await fetchData()
          })
        }
      }

      const queryData = () => {
        fetchData()
      }

      const copy = (row, event) => {
        handleClipboard(row.channelUrl, event)
      }
      const downLoad = (row) => {
        var image = new Image()
        image.setAttribute('crossOrigin', 'anonymous')
        var _this = this
        image.onload = function () {
          var canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          var context = canvas.getContext('2d')
          context.drawImage(image, 0, 0, image.width, image.height)
          var url = canvas.toDataURL('image/jpg') //得到图片的base64编码数据
          var a = document.createElement('a') // 生成一个a元素
          var event = new MouseEvent('click') // 创建一个单击事件
          a.download = '渠道二维码' // 设置图片名称
          a.href = url // 将生成的URL设置为a.href属性
          a.dispatchEvent(event) // 触发a的单击事件
        }
        image.src = row.channelQrcodeUrl
      }
      const fetchData = async () => {
        state.listLoading = true
        const { data } = await getChannelList(state.queryForm)
        state.list = data.data
        state.total = data.total
        state.listLoading = false
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
        queryData()
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNum = val
        queryData()
      }

      onActivated(() => {
        console.log(proxy.$route)
        state.queryForm.eventId = proxy.$route.query.eventId
        queryData()
      })
      return {
        ...toRefs(state),
        handleEdit,
        queryData,
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleDelete,
        copy,
        downLoad,
        Delete,
        Search,
        Plus,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
